<div class="m-2">
    <ng-container *ngIf="searchResults">
        <ul *ngIf="searchResults?.hasSucceeded" class="list-unstyled">
            <li *ngFor="let object of searchResults?.payload?.page; let i = index; let last = last" class="my-3 pb-3 d-flex" [class.border-bottom]="true && !last">
                <ds-listable-object-component-loader
                        [object]="object" [viewMode]="paginatedSearchOptions.view" [index]="i" [context]="context"
                        [listID]="paginatedSearchOptions.configuration"
                        [truncatableBackground]="truncatableBackground"
                ></ds-listable-object-component-loader>
            </li>
        </ul>
        <div *ngIf="searchResults?.hasFailed">
            {{ 'remote.error' | translate }}
        </div>
    </ng-container>
    <ds-loading *ngIf="!searchResults"></ds-loading>
</div>
