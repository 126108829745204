<div class="container">
    <div class="d-flex justify-content-between">
      <div *ngIf="isLoading$ | async">
        <ds-loading message="{{'loading.default' | translate}}"></ds-loading>
      </div>
     <div *ngFor="let counter of (counterData$ | async)"
        class="px-4 py-3 bg-blue-box d-flex align-items-center justify-content-center" (click)="goToLink(counter.link)" [ngStyle]="{'cursor': counter.link? 'pointer' : 'inherit'}">
          <div class="text-white mr-2">
              <i [ngClass]="counter.icon"></i>
          </div>
          <div class="text-white">
              {{'explore.counters-section.' + counter.label | translate}} <br />
              <span class="badge badge-light bg-white text-dark">{{counter.count}}</span>
          </div>
      </div>
    </div>
</div>
