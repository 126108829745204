<div class="d-flex item-font-size">
  <div class="truncatable-content">
    <div>
      <ds-type-badge class="d-inline-block" *ngIf="showLabel" [object]="dso"></ds-type-badge>
      <ds-truncatable [id]="dso.id" *ngIf="object !== undefined && object !== null">
        <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
          rel="noopener noreferrer" [routerLink]="[itemPageRoute]" class="lead item-list-title dont-break-out"
          [innerHTML]="dsoTitle"></a>
        <span *ngIf="linkType == linkTypes.None" class="item-list-title dont-break-out"
          [innerHTML]="dsoTitle"></span>
        <span class="text-muted">
          <ds-truncatable-part [id]="dso.id" [minLines]="1" [background]="truncatableBackground">
            <ng-container *ngIf="dso.firstMetadataValue('dc.publisher') || dso.firstMetadataValue('dc.date.issued')">
              (<span *ngIf="dso.firstMetadataValue('dc.publisher')" class="item-list-publisher"
                [innerHTML]="firstMetadataValue('dc.publisher') + ', '"></span>
              <span *ngIf="dso.firstMetadataValue('dc.date.issued')" class="item-list-date"
                [innerHTML]="firstMetadataValue('dc.date.issued')"></span>)
            </ng-container>
            <span *ngIf="dso.allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']).length > 0"
              class="item-list-authors">
              <span
                *ngFor="let author of dso.allMetadata(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']); let last=last;">
                <!-- <span [innerHTML]="author"><span [innerHTML]="author"></span></span> -->
                <ds-metadata-link-view [item]="dso" [metadata]="author"></ds-metadata-link-view>
                <span *ngIf="!last">; </span>
              </span>
            </span>
          </ds-truncatable-part>
        </span>
      </ds-truncatable>
      <ds-metric-badges *ngIf="!hideMetrics" class="d-block clearfix" [item]="dso"></ds-metric-badges>
    </div>
  </div>
  <div class="align-self-center">
      <ds-metric-donuts *ngIf="!hideMetrics" [item]="dso"></ds-metric-donuts>
  </div>
</div>
